import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import SurveyRegister from "../components/survey_register"

import awsExports from "../aws-exports";
import { withAuthenticator } from '@aws-amplify/ui-react'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
const PatientRegister = (props) => {

    return (
        <div>
            <Button color="primary" variant="contained" onClick={() => { }}>
                Sign out
            </Button>

            <SurveyRegister />
        </div>

    );
}

export default PatientRegister;