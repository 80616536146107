import React from 'react';
import { Doughnut } from 'react-chartjs-2';



const RagPie = (props) => {
    let propsdata = props.data
    if (propsdata.reduce((x, y) => x + y) === 0) {
        propsdata = [1, 0, 0]
    }
    var data = {
        labels: ['Green', 'Amber', 'Red'],
        datasets: [
            {
                label: 'RAGs',
                data: propsdata,
                backgroundColor: [
                    'rgba(29, 181, 140, 0.8)',

                    'rgba(237,188,52, 0.8)',
                    'rgba(184,24,32, 0.8)',


                ],
                borderColor: [
                    'rgba(29, 181, 140, 0.8)',

                    'rgba(237,188,52, 0.8)',
                    'rgba(184,24,32, 0.8)',

                ],
                borderWidth: 1,
            },
        ],
    };

    return (

        <>
            <div className='header'>


            </div>
            <Doughnut data={data} width={200} height={200} options={{
                responsive: false,
                maintainAspectRatio: true, plugins: {
                    legend: {
                        display: false
                    }
                }
            }} />
            <h3 className='title'>{props.title}</h3>
        </>
    );



}
export default RagPie;