import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red, amber, green, blue } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
// import { CircularProgressWithLabel } from "@material-ui/core";
import CircularProgressWithLabel from '@mui/material/CircularProgress';
const Task = (props) => {

    const url = props.button_link + '&uuid=' + props.uuid

    var header = props.header

    var complete = props.current_progress * 100 / props.total_actions
    if (props.status === 'done') {
        complete = 100
    }
    var subheader = props.subheader
    var retry_button = null
    if (props.selected_opt !== '' && props.selected_opt >= 0) {
        subheader = subheader + ' ' + props.dialog.options[props.selected_opt].task_update_staff
        if (props.dialog.options[props.selected_opt].retry_staff) {
            retry_button = props.dialog.options[props.selected_opt].retry_staff
        }
    }
    async function buttonPressed() {
        const url = props.awsUrl + '/task_reset?patient_id=' + props.patient_id + '&task_id=' + props.uuid
        const updatetask = await axios.get(url, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } });


    };
    return (
        <Card>

            <CardHeader
                avatar={
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgressWithLabel variant="determinate" value={complete} />
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography variant="caption" component="div" color="text.secondary">
                                {complete != 100 ? <> {`${Math.round(complete)}%`}</> :
                                    <>&#9989;</>}

                            </Typography>
                        </Box>
                    </Box>
                }
                titleTypographyProps={{ variant: 'h5' }}
                title={header}
                subheaderTypographyProps={{ variant: 'h6' }}
                subheader={subheader}
            />
            <CardContent>
                {retry_button ? <>
                    <Button autoFocus onClick={buttonPressed}>
                        {retry_button}
                    </Button></> : <>  </>}

            </CardContent>
        </Card>

    )

}

export default Task;