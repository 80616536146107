import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";

// here are the events for Survey https://surveyjs.io/Documentation/Library/?id=surveymodel#onValueChanged

// to be able to cache data the easiest way to do it is:
// locally read the latest answer set
// compare that with the last written set
// if different then write the cache to the user record
// this keeps all the data on the client side and reduces our data needs
// do this every 10 seconds and we pretty much won't lose much

const SurveyTestContainer = (props) => {


    var survey = new Survey.Model();

    const [cache, setCache] = useState('')
    const [interval, setIntervalPtr] = useState()

    useEffect(() => {
        Survey
            .StylesManager
            .applyTheme("modern");
    }, []);


    var surveyJSON = { surveyId: '90eb4183-9a0e-44e6-97a5-ba1373940ce5', surveyPostId: 'ccf07b12-c960-4500-8da3-2ea7ec9ec8dc' }


    async function sendDataToServer(d) {
        const r = await axios.post(awsvars.api_gw_survey_url + '/patient_test_survey', d, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } });
    }

    var survey = new Survey.Model(surveyJSON);
    survey.onComplete.add(function (survey_data) {
        sendDataToServer(survey_data.data)
    })

    return (
        <div>
            <Survey.Survey model={survey} />
        </div>

    );
}

export default SurveyTestContainer;