import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import SurveyTestContainer from "../components/survey_test"
import Amplify, { Auth } from "aws-amplify";
import awsExports from "../aws-exports";
import { withAuthenticator } from '@aws-amplify/ui-react'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
Amplify.configure(awsExports);
const PatientPortal = (props) => {
    const [idToken, setIdToken] = useState('')

    useEffect(() => {
        Auth.currentSession().then((res) => {
            let accessToken = res.getAccessToken();
            let jwt = accessToken.getJwtToken();
            setIdToken(res.getIdToken().jwtToken)
        });
    }, []);
    async function signOut() {
        try {
            await Auth.signOut();
            window.location.reload()
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }
  
    return (
        <div>
            <h1>Patient Portal - test survey 11</h1>
            <Button color="primary" variant="contained" onClick={() => { signOut(); }}>
                Sign out
            </Button>
            <SurveyTestContainer auth={idToken} surveyId="90eb4183-9a0e-44e6-97a5-ba1373940ce5" />

        </div>

    );
}
export default withAuthenticator(PatientPortal)
