import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
const SurveyContainer = (props) => {


    var survey = new Survey.Model();
    useEffect(() => {
        Survey
            .StylesManager
            .applyTheme("modern");
    }, []);


    var surveyJSON = { surveyId: '37d94cf0-334d-433a-b714-b0dd51344c9b', surveyPostId: '23c57c0a-bfab-4e5f-9cc8-f24042a4a1e9' }


    async function sendDataToServer(d) {
        const r = await axios.post(awsvars.api_gw_survey_url + '/patient_survey', d, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } });
    }

    var survey = new Survey.Model(surveyJSON);
    survey.onComplete.add(function (survey_data) {
        sendDataToServer(survey_data.data)
    })

    return (
        <div>
            <Survey.Survey model={survey} />
        </div>

    );
}

export default SurveyContainer;