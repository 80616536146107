import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
import AvrtGroup from "./avrtgroup"
import AvrtGroupSummary from "./avrtgroupsummary"
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red, yellow, green, blue } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Factor from "../ui_components/factor"
import Trigger from "../ui_components/trigger"
import External from "../ui_components/external"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';

const Serological = (props) => {
    const [seroData, setSeroData] = useState([])
    const [avrtData, setAvrtData] = useState([])
    const [triggers, setTriggers] = useState([])
    const [patient, setPatient] = useState({})
    const [headerData, setHeaderData] = useState([])
    const [external, setExternal] = useState({ 'fields': [] })
    const [patientName, setPatientName] = useState({})
    const [avrt, setAvrt] = useState({})
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (props.awsUrl === '') {
            return
        }
        async function fetch() {
            if (!props.patient_id) {
                return
            }
            await axios.get(props.awsUrl + '/serology_staff?patient_id=' + props.patient_id, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } }).then(
                function (response) {
                    setSeroData(response.data.remap)
                    setLoaded(true)
                }
            )
        }
        if (!props.auth) {
            console.log('exiting cos props.auth is ', props.auth)
            return
        }
        fetch()
    }, [props.awsUrl, props.auth, props.patient_id]);


    return (
        <div>

            <h2>Serological Data</h2>
            {(loaded && seroData) ?
                <>
                    <Grid container spacing={2}>
                        {
                            seroData.map(({ field, value, units, display, bloods_key }) => {
                                if (display === true) {
                                    return (
                                        <Grid item xs={6} md={4} lg={3}>

                                            <b>{bloods_key}</b><br />{value} {units}
                                        </Grid>

                                    )
                                }

                            }

                            )}
                    </Grid>
                </> : <>< CircularProgress /></>
            }
        </div >

    );
}

export default Serological;