import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "./index.css";
import SurveyContainer from "./components/survey"

import AVRT from "./pages/AVRT";
import PatientRegister from "./pages/PatientRegister";
import StaffPortal from "./pages/StaffPortal";
import Patient from "./pages/Patient";
import PatientSurvey from "./pages/PatientSurvey";
import PatientTestSurvey from "./pages/PatientTestSurvey";
import Container from '@mui/material/Container';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
//https://stackoverflow.com/questions/48319372/changing-font-family-of-all-mui-components
function App() {


  const theme = createTheme({
    typography: {
      fontFamily: [
        'Montserrat',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif'
      ].join(','),
    }
  });

  function sendDataToServer() {

  }

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div>
          <Container maxWidth="lg">
            <Switch>
              <Route exact path='/' component={StaffPortal} />
              <Route path='/register' component={PatientRegister} />
              <Route path='/staff' component={StaffPortal} />
              <Route path='/patient_survey' component={PatientSurvey} />
              <Route path='/patient' component={Patient} />
              <Route path='/patient_test_survey' component={PatientTestSurvey} />
            </Switch>
          </Container>
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;
