import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red, amber, green } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
const Trigger = (props) => {

    const [avrtData, setAvrtData] = useState({})
    const [ragColour, setRagColour] = useState(red[500])
    const [title, setTitle] = useState("")
    const [details, setDetails] = useState("")

    useEffect(() => {
        if (props.details) {
            if (props.details.rag === 'red') {
                setRagColour("#b81820")
            }
            if (props.details.rag === 'amber') {
                setRagColour("#f5b131")
            }
            if (props.details.rag === 'green') {
                setRagColour("#1db58c")
            }
            setTitle(props.details.msg)
        }
        setDetails(props.display + ': ' + props.value)
    }, []);


    return (
        <div>
            <Card>

                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: ragColour }} aria-label="recipe">
                            &nbsp;
                        </Avatar>
                    }
                    titleTypographyProps={{ variant: 'h5' }}
                    title={title}
                    subheaderTypographyProps={{ variant: 'h6' }}
                    subheader={details}
                />

            </Card>
        </div>



    );
}

export default Trigger;