import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import SurveyContainer from "../components/survey"
import AvrtData from "../components/avrtdata"
import Serological from "../components/serological"
import PatientSearch from "../components/patient_search"
import Amplify, { Auth } from "aws-amplify";
import awsExports from "../aws-exports";
import { withAuthenticator, AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react'
// import { Button, t } from '@material-ui/core';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red, yellow, green, blue } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Factor from "../ui_components/factor"
import awsvars from "../aws.json"
import axios from "axios";
import * as qs from 'query-string';
import PatientDocs from "../components/PatientDocs"
import Task from "../components/task"
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import VitallEvents from "../components/VitallEvents"
import awsGlobal from "../aws-global"
Amplify.configure(awsExports);
const Patient = (props) => {
    const [idToken, setIdToken] = useState('')
    const [patientName, setPatientName] = useState('')
    const [patientAddress, setPatientAddress] = useState('')
    const [patientDOB, setPatientDOB] = useState('')
    const [patientPhone, setPatientPhone] = useState('')
    const [patientGender, setPatientGender] = useState('')
    const [patientEmail, setPatientEmail] = useState('')
    const [avatarInitials, setAvatarInitials] = useState('')
    const [patientID, setPatientID] = useState('')
    const [params, setParams] = useState({})
    const [tasks_loaded, setTasksLoaded] = useState(false)
    const [tasks, setTasks] = useState([])
    const [cognitoStatus, setCognitoStatus] = useState('')
    const [cognitoShowResend, setCognitoShowResend] = useState(false)
    //hack just for the proforma at launch
    const [allergies, setAllergies] = useState('')
    const [medications, setMedications] = useState('')
    const [supplements, setSupplements] = useState('')
    const [stopResend, setStopResend] = useState(false)

    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();
    const [cognito, setCognito] = useState({})
    const [region, setRegion] = useState('')
    const [awsUrl, setAwsUrl] = useState('')
    const [rootRegion, setRootRegion] = useState('uk2')
    const [regionName, setRegionName] = useState('')


    const readPatient = async (id, auth, region) => {
        var fetch_url = awsGlobal.aws_json_staff[awsGlobal.env][region].api_gw_url + `/patient?patient_id=` + id;
        if (region === 'us') {
            fetch_url = awsGlobal.aws_json_staff[awsGlobal.env][region].api_gw_url + `/patient?drchrono_id=` + id;
        }
        var response = await axios.get(fetch_url, { headers: { Authorization: 'Bearer ' + auth, 'Content-Type': 'application/json' } });
        setPatientName((response.data.first_name + ' ' + response.data.middle_name + ' ' + response.data.last_name).replace('  ', ' '))
        var addr = ''
        if (response.data.address) {
            if (addr != '') {
                addr += ', '
            }
            addr += response.data.address
        }
        if (response.data.city) {
            if (addr != '') {
                addr += ', '
            }
            addr += response.data.city
        }
        if (response.data.state) {
            if (addr != '') {
                addr += ', '
            }
            addr += response.data.state
        }
        if (response.data.zip_code) {
            if (addr != '') {
                addr += ', '
            }
            addr += response.data.zip_code
        }

        setPatientAddress(addr)
        setPatientPhone(response.data.home_phone)
        setPatientDOB(response.data.date_of_birth)
        setPatientGender(response.data.gender)
        setPatientEmail(response.data.email)
        setAvatarInitials(response.data.first_name[0] + response.data.last_name[0])
        setCognitoStatus(response.data.cognito_status)

        if (response.data.cognito_status && response.data.cognito_status.includes('initial password is')) {
            setCognitoShowResend(true)
        }
        setAllergies(response.data.allergies)
        setMedications(response.data.medications)
        setSupplements(response.data.supplements)

    }
    const getTasks = async (auth, patient_id, region) => {
        var fetch_url = awsGlobal.aws_json_staff[awsGlobal.env][region].api_gw_url + `/patient_task_staff?patient_id=` + patient_id;
        var response = await axios.get(fetch_url, { headers: { Authorization: 'Bearer ' + auth, 'Content-Type': 'application/json' } });
        setTasks(response.data)
        setTasksLoaded(true)
    }
    const resend_initial_email = async () => {
        var paramsx = qs.parse(window.location.search, { ignoreQueryPrefix: true })
        var fetch_url = awsvars.api_gw_url + `/resend_initial_email?patient_id=` + paramsx.patient;
        var response = await axios.get(fetch_url, { headers: { Authorization: 'Bearer ' + idToken, 'Content-Type': 'application/json' } });
    }

    useEffect(() => {
        if (authState === undefined) {
            Auth.currentAuthenticatedUser().then(authData => {
                setAuthState(AuthState.SignedIn);
                setUser(authData);
            });
        }
        onAuthUIStateChange((nextAuthState, authData) => {
            const region_translate = { 'uk': 'United Kingdom', 'uk2': 'United Kingdom', 'us': 'United States', 'us3': 'United States' }
            setAuthState(nextAuthState);
            setUser(authData)
            Auth.currentSession().then((res) => {
                let accessToken = res.getAccessToken();
                let jwt = accessToken.getJwtToken();
                //You can print them to see the full objects
                setIdToken(res.getIdToken().jwtToken)
                var paramsx = qs.parse(window.location.search, { ignoreQueryPrefix: true })
                setParams(paramsx)
                setPatientID(paramsx.patient)
                setRegion(paramsx.region)
                setRegionName(region_translate[paramsx.region])
                if ('patient' in paramsx) {
                    //fetch patient details and store data
                    readPatient(paramsx['patient'], res.getIdToken().jwtToken, paramsx.region)
                }
                setAwsUrl(awsGlobal.aws_json_staff[awsGlobal.env][paramsx.region].api_gw_url)
                getTasks(res.getIdToken().jwtToken, paramsx.patient, paramsx.region)
            })
        });

    }, [authState]);

    return (
        <div>
            <h1><a href="/staff">AVRT Staff Portal</a></h1>
            <h1>PATIENT INFORMATION</h1>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Card >
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe">
                                    {avatarInitials}
                                </Avatar>
                            }
                            titleTypographyProps={{ variant: 'h4' }}
                            title={patientName}
                            subheaderTypographyProps={{ variant: 'h6' }}
                            subheader={patientAddress}

                        />
                        <CardContent>
                            <b>Country: {regionName}</b><br />
                            Email: {patientEmail}<br />
                            Telephone: {patientPhone}<br />
                            Date of Birth: {patientDOB}<br />
                            Gender: {patientGender}<br />
                            Account Status: {cognitoStatus}<br />
                            {cognitoShowResend ?
                                <><Button variant="contained" disabled={stopResend} onClick={async () => { setStopResend(true); resend_initial_email() }}>Resend welcome email (with patient portal credentials)</Button><br /><br /></> :
                                <></>}
                        </CardContent>

                    </Card>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>

                    <h2>Patient tasks</h2>
                    {tasks_loaded ?
                        <>
                            {
                                tasks.map(({ id, status, type, uuid, header, subheader, description, progress, button, button_link, current_progress, total_actions, pass_uuid, dialog, selected_opt }) => {
                                   
                                    if (status != 'done') {
                                        return (
                                            <Task id={id} status={status} type={type} uuid={uuid} header={header} subheader={subheader} description={description} progress={progress} button={button} button_link={button_link} current_progress={current_progress} total_actions={total_actions} pass_uuid={pass_uuid} dialog={dialog} auth={idToken} selected_opt={selected_opt} awsUrl={awsUrl} patient_id={patientID} />
                                        )
                                    }



                                }
                                )
                            } </> : <>< CircularProgress /></>}
                    <h2>Completed tasks</h2>
                    {tasks_loaded ?
                        <>
                            {
                                tasks.map(({ id, status, type, uuid, header, subheader, description, progress, button, button_link, current_progress, total_actions, pass_uuid, dialog, selected_opt }) => {
                                    if (status == 'done') {
                                        return (
                                            <Task id={id} status={status} type={type} uuid={uuid} header={header} subheader={subheader} description={description} progress={progress} button={button} button_link={button_link} current_progress={current_progress} total_actions={total_actions} pass_uuid={pass_uuid} dialog={dialog} auth={idToken} selected_opt={selected_opt} awsUrl={awsUrl} patient_id={patientID} />
                                        )
                                    }


                                }
                                )
                            } </> : <>< CircularProgress /></>}
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <h2>Patient documents</h2>
                    <PatientDocs auth={idToken} patient_id={patientID} awsUrl={awsUrl} />
                </Grid>
                {regionName === "United Kingdom" ?
                    <> <Grid item xs={12} md={12} lg={12}>
                        <h2>Vitall order tracking (UK only)</h2>
                        <VitallEvents auth={idToken} patient_id={patientID} awsUrl={awsUrl} />
                    </Grid> </> : <></>}

            </Grid>
            <Card variant="outlined">
                <Serological auth={idToken} patient_id={patientID} awsUrl={awsUrl} />

            </Card>
            <Card variant="outlined">
                <AvrtData auth={idToken} patient_id={patientID} awsUrl={awsUrl} />

            </Card>
        </div>

    );
}
export default withAuthenticator(Patient)
