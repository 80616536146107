import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
import { Doughnut } from 'react-chartjs-2';
import { Grid } from '@material-ui/core';
import RagPie from "../ui_components/ragpie.js"

// we use this for charts https://www.npmjs.com/package/react-chartjs-2
const AvrtGroupSummary = (props) => {

    const [avrtData, setAvrtData] = useState({})
    const [slices, setSlices] = useState([0.1, 0.45, 0.2])
  
    useEffect(() => {



    }, []);

  
    return (



        <div>
          
            <a href={'#' + props.id}>
                <RagPie data={[props.rag.green, props.rag.amber, props.rag.red ]} title={props.name} />
            </a>
           
        </div>


    );
}

export default AvrtGroupSummary;