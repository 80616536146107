import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
import AvrtGroup from "./avrtgroup"
import AvrtGroupSummary from "./avrtgroupsummary"
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red, yellow, green, blue } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Factor from "../ui_components/factor"
import Trigger from "../ui_components/trigger"
import External from "../ui_components/external"
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { compareDocumentPosition } from "htmlparser2/node_modules/domutils";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import awsGlobal from "../aws-global"
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@material-ui/data-grid';
const PatientSearch = (props) => {
    const [patientsRaw, setPatientsRaw] = useState([])
    const [patients, setPatients] = useState([])
    const [patientGrid, setPatientGrid] = useState([])

    const [avrtData, setAvrtData] = useState([])
    const [triggers, setTriggers] = useState([])

    const [headerData, setHeaderData] = useState([])
    const [external, setExternal] = useState({ 'fields': [] })

    const [sortPatients, setSortPatients] = useState(true)
    const [showTestPatients, setShowTestPatients] = useState(false)
    const [gridDataRaw, setGridDataRaw] = useState([])
    const [loaded, setLoaded] = useState(false)


    function order(a, b) {
        return a < b ? -1 : (a > b ? 1 : 0);
    }

    function isTest(a) {
        if (a.firstname.toLowerCase().includes('test') || a.surname.toLowerCase().includes('test')) {
            return false
        }
        return true
    }

    const applyPatientRules = async () => {
        let a = [...gridDataRaw]
        if (!showTestPatients) {
            a = a.filter(isTest)
        }
        setPatientGrid(a)
    }

    const searchPatients = async (search, region) => {
        if (region) {
            var fetch_url = awsGlobal.aws_json_staff[awsGlobal.env][region].api_gw_url + `/patients`;
            return axios.get(fetch_url, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } })

        }
    }

    const getPatients = async (search, regions) => {
        let patients = []
        let maps = []
        const region_translate = { 'uk': 'United Kingdom', 'uk2': 'United Kingdom', 'us': 'United States', 'us3': 'United States' }
        for (const x of regions.split(',')) {
            let res = await searchPatients('', x)
            patients = patients.concat(res)
            maps.push(x)
        }
        Promise.all(patients).then(res => {
            console.log('patients are', res)
            let p = []
            let c = 0
            let pg = []
            for (const x of res) {
                if (x) {
                    for (const xx in x.data) {
                        let pgr = {}
                        x.data[xx]['region_aws'] = maps[c]
                        pgr.id = x.data[xx].user_id
                        pgr.firstname = x.data[xx].firstname
                        pgr.surname = x.data[xx].surname
                        pgr.email = x.data[xx].email
                        pgr.patient_id = x.data[xx].patient_id
                        pgr.region_aws = maps[c]
                        pgr.updated_at = x.data[xx].updated_at
                        pgr.region_name = region_translate[maps[c]]
                        pg = pg.concat(pgr)
                    }
                    p = p.concat(x.data)
                }
                c += 1
            }
            setPatientsRaw(p)
            setGridDataRaw(pg)
            return res
        })

    }
    useEffect(() => {
        applyPatientRules()
        if (gridDataRaw.length > 0) {
            setLoaded(true)
        }

    }, [gridDataRaw])
    useEffect(() => {
        if (props.auth !== '') {
            console.log('need to fetch patients for regions:', props.region)
            getPatients('', props.region)

        }
        if (awsGlobal.env != 'prod') {
            setShowTestPatients(true)
        }
    }, [props.auth, props.region]);

    useEffect(() => {
        applyPatientRules()
    }, [patientsRaw, sortPatients, showTestPatients])



    const handleChangeFilter = (event) => {
        setShowTestPatients(event.target.checked)
        applyPatientRules()
    }

    const grid_columns = [
        { field: 'firstname', headerName: 'First Name', sortable: false, width: 150 },
        { field: 'surname', headerName: 'Surname', sortable: false, width: 150 },
        { field: 'email', headerName: 'Email', sortable: false, width: 250 },
        { field: 'updated_at', headerName: 'Last updated', width: 200 },
        { field: 'region_name', headerName: 'Region', sortable: false, width: 150 },
    ]

    const [sortModel, setSortModel] = React.useState([
        {
            field: 'updated_at',
            sort: 'desc',
        },
    ]);
    return (
        <div>
            <h1>PATIENTS</h1>
            &nbsp;&nbsp;&nbsp;
            <FormControlLabel
                label="Show patients with 'Test' in their name"
                control={
                    <Checkbox
                        checked={showTestPatients}
                        onChange={handleChangeFilter}
                    />
                }
            />
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>Dates are in UTC/GMT and represent the last time the Dr Chrono record was updated, and not user action within their AVRT Patient Portal</Grid>

            </Grid>
            <Grid>
                {loaded ?
                    <> <Grid container spacing={2}> <div style={{ height: 800, width: '100%' }}>

                        <DataGrid
                            rows={patientGrid}
                            columns={grid_columns}
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                            sortModel={sortModel}
                            onCellClick={(e) => {
                                console.log(e);
                                window.location = "patient?patient=" + e.row.patient_id + "&region=" + e.row.region_aws
                            }}

                        />

                    </div> </Grid> </> : <><CircularProgress /></>
                }
            </Grid>
        </div >

    );
}

export default PatientSearch;