import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
import Factor from "../ui_components/factor"
import { Grid, Card } from '@material-ui/core';
import AvrtElement from "./avrtelement"
const AvrtGroup = (props) => {

    const [avrtData, setAvrtData] = useState({})
    useEffect(() => {



    }, []);


    return (
        <div>

            <div>
                <a id={props.id}><h3>{props.name}
                    <br />
                
                </h3></a>
              
                <Grid container spacing={2}>
                   
                    {props.data.map(({ group_name, rag_overall, fields, header_value, header_units, display }) => {
                     
                        return (
                            <Grid item xs={12} md={6} lg={4}>
                                <AvrtElement group_name={group_name} rag_overall={rag_overall} fields={fields} header_value={header_value} header_units={header_units} />
                            </Grid>)

                  

                    })}
                </Grid>
              
            </div>
        </div >

    );
}

export default AvrtGroup;